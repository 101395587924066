import React from 'react'
import ReactPlayer from 'react-player'
import './videoPlayer.scss'


const VideoPlayer = () => {
  return (
    <div className=''>
      <div className='box-title'>
        <div className='content-wrapper'>
          <h2 className='teks-decoration green'>Video Panduan</h2>
          <h4 className='teks-decoration'>Penggunaan Aplikasi Gugatan Mandiri</h4>
        </div>
      </div>

      <div className='video-box-player'>
        <div className='content-wrapper box-video'>
          <ReactPlayer width="100%" height="100%" url='https://www.youtube.com/embed/3liqVmm_D2g' />
        </div>
      </div>

    </div>
  )
}

export default VideoPlayer
